import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/ua/chapter_4/sub_6/slide1';


import WrapperMobile from 'src/slides/mobile/ua/chapter_4/sub_6/wrapperMobile';
import { isMobile } from 'react-device-detect';
// import Slide
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Mobile = () => (
  <>
    <SEO title="Хто був автором плану битви? I Варшавська битва" lang="ua" description="Найголовніші люди, які сприяли успіху Польщі в 1920 році." />
    <WrapperMobile />
  </>
);

const Desktop = () => (
  <>
    <SEO title="Хто був автором плану битви? I Варшавська битва" lang="ua" description="Найголовніші люди, які сприяли успіху Польщі в 1920 році." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
